@import url('https://fonts.googleapis.com/css2?family=Borel&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import './variables.scss';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

html,
body {
  max-width: 100svw;
  overflow-x: hidden;
  position: relative;
}

html {
  background-image: linear-gradient(109.6deg, rgba(156, 252, 248, 1) 11.2%, rgba(110, 123, 251, 1) 91.1%);
}

body {
  background-color: white;
  margin-inline: auto;
  max-width: 600px;
  width: 100%;
  min-height: 100svh;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
}

a {
  color: inherit;
  text-decoration: none;
}

.bottomHeadroom .headroom {
  top:100%!important;
}

.bottomRoom {
  width: 100%;
  display: flex;
  justify-content:stretch;
  align-items: center;
  background-color: #FFFFFF;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  z-index: 40;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
  transition: all 0.3s linear;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  position: fixed;
}

.bottomRoom.active {
  // position: fixed;
  transform: translate3d(0px, 0PX, 0px);
}
.bottomRoom.hidden {
  // position: relative;
  transform: translate3d(0px, 100%, 0px);
}

.bottomRoom-wrapper {
  height: 67px;
}

.underlined {
  text-decoration: underline;
}

.centerAlignTypography {
  display: flex;
  align-items: center;
}

.swiper-slide {
  text-align: center;
}

.found {
  color: green; 
}

.snoozed {
  color: black; 
}

.unavailable {
  color: red; 
}

.MuiGrid-item {
  padding-bottom: 4px;
}

.blink_me {
  // animation: blinker 1s linear infinite;
  animation: 1s ease-in 1s infinite reverse both running blinker;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}